import { Button, Textarea, Input, Select } from "@chakra-ui/react";
import { useRef, useState } from "react";

import axios from "axios";

import { FiActivity } from "react-icons/fi";

export interface IGetUserBlockyChallengeProgressProps {}

export default function GetUserBlockyChallengeProgress(
	props: IGetUserBlockyChallengeProgressProps
) {
	const authorizationTokenRef = useRef<HTMLTextAreaElement>(null);
	const progressChallengeID = useRef<HTMLInputElement>(null);
	const latestProgressRef = useRef<HTMLSelectElement>(null);
	const [resData, setResData] = useState("");
	const [callBtnLoading, setCallingBtnLoading] = useState(false);
	function getUserBlockyChallengeProgress() {
		setCallingBtnLoading(true);
		axios
			.get("/user/challenge/progress", {
				headers: {
					Authorization: authorizationTokenRef.current!.value,
				},
				params: {
					challengeID: progressChallengeID.current!.value,
					latestProgress: latestProgressRef.current!.value
				},
			})
			.then((response) => {
				const data = response.data;
				setResData(JSON.stringify(data, null, 4));
				setCallingBtnLoading(false);
			})
			.catch((err: Error) => {
				setResData(JSON.stringify(err, null, 4));
				setCallingBtnLoading(false);
			});
	}

	return (
		<div>
			<p>Authorization Token:</p>
			<Textarea
				ref={authorizationTokenRef}
				placeholder="Create an account at https://dashboard.codetowers.com & paste in the authorization token here"
				mb="20px"
			/>
			<p>
				Latest Blocky Progress:
			</p>
			<Select ref={latestProgressRef} placeholder="Please select">
				<option value="true">true</option>
				<option value="false">false</option>
			</Select>
			<Input
				ref={progressChallengeID}
				placeholder="Challenge ID:"
				size="md"
				variant="flushed"
				mt="10px"
			/>
			<div style={{ textAlign: "right" }}>
				<Button
					colorScheme="blue"
					mt="15px"
					mr="20px"
					mb="40px"
					onClick={getUserBlockyChallengeProgress}
					isLoading={callBtnLoading}
					loadingText="Calling"
					leftIcon={<FiActivity />}
				>
					Call
				</Button>
			</div>
			<pre className="resultData">{resData}</pre>
		</div>
	);
}
