import { ChakraProvider, Box, theme } from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";

import "./config/axios";
import CodeTowersRoutes from "./config/routes";

// import  from "./pages/get/get-user-power-rating"

export const App = () => (
	<ChakraProvider theme={theme}>
		<Box fontSize="xl">
			<ColorModeSwitcher justifySelf="flex-end" />
			<Box width="80%" maxW="600px" ml="auto" mr="auto">
				<CodeTowersRoutes />
			</Box>
		</Box>
	</ChakraProvider>
);
