import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "../pages/home";
import GetUserPowerRating from "../pages/get/user-power-rating";
import GetUserTotalChallengesCompleted from "../pages/get/user-total-challenge-completed";
import GetUserNextChallengeId from "../pages/get/user-next-challenge-id";
import GetUserChallengeStatus from "../pages/get/user-challenge-status";
import GetUserBlockyChallengeProgress from "../pages/get/user-blocky-challenge-progress";
import PutUserBlockyChallengeProgress from "../pages/put/user-blocky-challenge-progress";
import PutUserCompletedBlockyChallenge from "../pages/put/user-completed-blocky-challenge";
import GetUserBlockyChallengeJSON from "../pages/get/get-user-blocky-challenge-json";

export interface ICodeTowersRoutesProps {}

export default function CodeTowersRoutes(props: ICodeTowersRoutesProps) {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route
						path="/get-user-power-rating"
						element={<GetUserPowerRating />}
					></Route>
					<Route
						path="/get-user-total-challenge-completed"
						element={<GetUserTotalChallengesCompleted />}
					></Route>
					<Route
						path="/get-user-next-challenge-id"
						element={<GetUserNextChallengeId />}
					></Route>
					<Route
						path="/get-user-challenge-status"
						element={<GetUserChallengeStatus />}
					></Route>
					<Route
						path="/get-user-blocky-challenge-progress"
						element={<GetUserBlockyChallengeProgress />}
					></Route>
					<Route
						path="/get-user-blocky-challenge-json"
						element={<GetUserBlockyChallengeJSON/>}
					></Route>
					<Route
						path="/put-user-blocky-challenge-progress"
						element={<PutUserBlockyChallengeProgress />}
					></Route>
					<Route
						path="/put-user-complete-challenge"
						element={<PutUserCompletedBlockyChallenge />}
					></Route>
				</Routes>
			</Router>
		</div>
	);
}
