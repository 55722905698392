import { Button, Textarea } from "@chakra-ui/react";
import { useRef, useState } from "react";

import axios from "axios";

import { FiActivity } from "react-icons/fi"

export interface IGetUserNextChallengeIdProps {}

export default function GetUserNextChallengeId(props: IGetUserNextChallengeIdProps) {
	const authorizationTokenRef = useRef<HTMLTextAreaElement>(null);
	const [resData, setResData] = useState("");
  const [callBtnLoading, setCallingBtnLoading] = useState(false)
	function getUserNextChallengeId() {
    setCallingBtnLoading(true)
		axios
			.get("/user/challenge/next", {
				headers: {
					Authorization: authorizationTokenRef.current!.value,
				},
			})
			.then((response) => {
				const data = response.data;
				setResData(JSON.stringify(data, null, 4));
        setCallingBtnLoading(false)
			})
			.catch((err: Error) => {
				setResData(JSON.stringify(err, null, 4));
        setCallingBtnLoading(false)
			});
	}

	return (
		<div>
			<p>Authorization Token:</p>
			<Textarea
				ref={authorizationTokenRef}
				placeholder="Create an account at https://dashboard.codetowers.com & paste in the authorization token here"
			/>
			<div style={{textAlign:"right"}}>
				<Button
					colorScheme="blue"
					mt="15px"
					mr="20px"
          mb="40px"
					onClick={getUserNextChallengeId}
          isLoading={callBtnLoading}
          loadingText="Calling"
          leftIcon={<FiActivity />}
				>
					Call
				</Button>
			</div>
			<pre className="resultData">{resData}</pre>
		</div>
	);
}
