import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
export interface IHomeProps {}

export default function Home(props: IHomeProps) {
	return (
		<div>
			<Box textAlign={["center"]}>
				<Link to="/get-user-power-rating" className="navLink">
					GET: User power rating
				</Link>
				<Link
					to="/get-user-total-challenge-completed"
					className="navLink"
				>
					GET: User total challenge completed
				</Link>
				<Link to="/get-user-next-challenge-id" className="navLink">
					GET: User next challenge id
				</Link>
				<Link to="/get-user-challenge-status" className="navLink">
					GET: User challenge status
				</Link>
				<Link
					to="/get-user-blocky-challenge-progress"
					className="navLink"
				>
					GET: User blocky challenge progress
				</Link>
				<Link
					to="/get-user-blocky-challenge-json"
					className="navLink"
				>
					GET: User blocky challenge JSON
				</Link>
				<Link
					to="/put-user-blocky-challenge-progress"
					className="navLink"
				>
					PUT: User blocky challenge progress
				</Link>
				<Link to="/put-user-complete-challenge" className="navLink">
					PUT: User complete challenge
				</Link>
			</Box>
		</div>
	);
}
